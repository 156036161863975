const MIN_DIFF = 3;

const clusterify = (points, zoomLevels = 4, passes = 1) => {
  const levels = [];
  for (var i = 0; i < zoomLevels; i++) {
    for (var p = 0; p < passes; p++) {
      if (levels[i]) {
        levels[i] = clusterifyZoomLevel(levels[i], zoomLevels - 1 - i);
      } else {
        levels[i] = clusterifyZoomLevel(points, zoomLevels - 1 - i);
      }
    }
  }
  return levels;
};

const clusterifyZoomLevel = (pts, zoomLevel) => {
  const DISTANCE = MIN_DIFF * zoomLevel;
  let points = [...pts];

  for (let i = 0; i < points.length; i++) {
    for (let k = 0; k < points.length; k++) {
      const point = points[i];
      const otherPoint = points[k];
      // don't compare the same point, duh
      if (!point || !otherPoint || point.slug === otherPoint.slug) {
        continue;
      }

      const distance = distanceBetweenTwoPoints(
        point.location,
        otherPoint.location
      );

      if (points.indexOf(point) === -1) {
        continue;
      }
      let cluster;
      if (distance < DISTANCE) {
        if (point.isCluster === otherPoint.isCluster) {
          cluster = createCluster(point, otherPoint);
          points.splice(points.indexOf(point), 1);
          points.splice(points.indexOf(otherPoint), 1);
          points.push(cluster);
        } else if (point.isCluster && !otherPoint.isCluster) {
          addElementToCluster(point, otherPoint);
          points.splice(points.indexOf(otherPoint), 1);
        } else if (!point.isCluster && otherPoint.isCluster) {
          addElementToCluster(otherPoint, point);
          points.splice(points.indexOf(point), 1);
        }
      }
    }
  }
  for (let i = 0; i < points.length; i++) {
    points[i].slug = points[i].slug.split('|').sort().join('|');
  }
  return points;
};

const createCluster = (p1, p2) => {
  const obj = {};
  const center = midPoint(
    [p1.location.lat, p1.location.lng],
    [p2.location.lat, p2.location.lng]
  );
  obj.isCluster = true;
  obj.location = { lat: center[0], lng: center[1] };
  obj.points = [p1, p2];
  obj.totalPoints =
    (p1.points ? p1.totalPoints : 1) + (p2.points ? p2.totalPoints : 1);
  obj.slug = `${p1.slug}|${p2.slug}`;
  return obj;
};

const addElementToCluster = (cluster, p1) => {
  const center = midPoint(
    [cluster.location.lat, cluster.location.lng],
    [p1.location.lat, p1.location.lng]
  );

  cluster.location = { lat: center[0], lng: center[1] };
  cluster.totalPoints = cluster.totalPoints + 1;
  cluster.points.push(p1);
  cluster.slug = `${cluster.slug}|${p1.slug}`;
};

const midPoint = ([x1, y1], [x2, y2]) => [(x1 + x2) / 2, (y1 + y2) / 2];

const distanceBetweenTwoPoints = (p1, p2) => {
  const diffX = p2.lat - p1.lat;
  const diffY = p2.lng - p1.lng;
  return Math.sqrt(diffX * diffX + diffY * diffY);
};

export default clusterify;
