import * as THREE from "three";

const defaultQuad = new THREE.PlaneBufferGeometry(2, 2, 1, 1);
const defaultVertexShader = [
  "uniform vec4 uSize;",
  "uniform vec2 uSide;",
  "varying vec2 vUv;",
  "void main(){",
  "vUv = uv;",
  "vec2 transformed = position.xy * uSize.xy;",
  "transformed += vec2( -1. , 1. ) - vec2( - uSize.x , uSize.y );",
  "transformed += uSide - uSide * vec2( uSize.x , uSize.y );",
  "transformed += uSide * vec2( - uSize.w , - uSize.z ) * 2.;",
  "gl_Position = vec4( transformed , 1. , 1. );",
  "}",
].join("\n");
import defaultFragmentShader from "./shader.frag";

export default class ScreenQuad {
  constructor() {
    this.mesh = new THREE.Mesh(
      defaultQuad,
      new THREE.ShaderMaterial({
        uniforms: {
          uMultiplier: {
            type: "t",
            value: 2,
          },
          uSpeed: {
            type: "t",
            value: 0.5,
          },
          uTime: {
            type: "t",
            value: 1.0,
          },
          uSize: {
            type: "v4",
            value: new THREE.Vector4(1, 1, 0, 0),
          },
          uSide: {
            type: "v2",
            value: new THREE.Vector2(1, 1),
          },
        },
        vertexShader: defaultVertexShader,
        fragmentShader: defaultFragmentShader,
        // depthWrite: false,
        // depthTest: false,
        // blending: THREE.AdditiveBlending,
        // blendEquation: THREE.AddEquation,
        // blendSrc: THREE.SrcAlphaFactor,
        // blendDst: THREE.DstAlphaFactor,
      })
    );
    // this.mesh.frustumCulled = false;
    // this.mesh.renderOrder = 10;

    return this.mesh;
  }
}
