<template>
  <div class="home">
  </div>
</template>

<script>
// import gsap from "gsap";
export default {
  name: "Home",
  mounted() {},
};
</script>
<style lang="scss">
div.home {
  position: relative;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  font-family: "Josefin Sans";
  font-style: normal;
  font-weight: 400;
  max-width: 1920px;
  margin: 0 auto;
  pointer-events: none;
}
</style>
