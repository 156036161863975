<template>
  <div
    class="map"
    @click="onClick()"
  >
    <div id="map"></div>
    <div class="info">
      {{ this.$route.params.name }}<br/>
      <br/>
      Explanatory text about the event. Explanatory text about the event. Explanatory text about the event. Explanatory text about the event. Explanatory text about the event. Explanatory text about the event. Explanatory text about the event. Explanatory text about the event.
    </div>
    <div class="images">IMAGES</div>
  </div>
</template>

<script>
import gsap from "gsap";
import { Loader } from "@googlemaps/js-api-loader"
import DataLoader from "@/core/DataLoader";

console.log("process.env.VUE_APP_GOOGLE_API_KEY", process.env.VUE_APP_GOOGLE_API_KEY);
const additionalOptions = {};
const loader = new Loader({
  apiKey: process.env.VUE_APP_GOOGLE_API_KEY,
  version: "beta",
  // channel: 2,
  ...additionalOptions,
});
const loaded = loader.load().then(() => {
  console.log("loaded Google Maps loader");
});

export default {
  name: "Map",
  methods:{
    onClick(){
      console.log("onClick");
      this.$router.go(-1);
    }
  },
  mounted() {
    console.log("mounted", this.$route.params.name);
    loaded.then(() => {
      const points = DataLoader.CLUSTERED_PLACES[DataLoader.CLUSTERED_PLACES.length - 1];
      let location = {lat:0, lng:0, tilt: 70, zoom: 14, heading: 0};
      for (let i = 0; i < points.length; i++) {
        const point = points[i];
        if (point.slug === this.$route.params.name) {
          location = point.location;
          break;
        }
      }

      // const map =
      new window.google.maps.Map(document.getElementById("map"), {
        center: location,
        zoom: location.zoom,
        tilt: location.tilt,
        heading: location.heading,
        mapId: process.env.VUE_APP_GOOGLE_MAP_ID,

        gestureHandling: "none",
        zoomControl: false,
        mapTypeId: "satellite",
        disableDefaultUI: true,
        styles: [
          {
            featureType: "all",
            elementType: "labels",
            stylers: [
              { visibility: "off" }
            ]
          }
        ]
      });
      // map.setTilt(location.tilt);

    }).catch(error => {
      console.error(error);
    })
    this.$nextTick(() => {
      this.opacity = 0;
      this.$el.style.opacity = 0;
      gsap.killTweensOf(this);
      gsap.to(this, {
        opacity: 1,
        ease: "ease.in",
        duration: 1,
        onUpdate: () => {
          // console.log(this.opacity);
          this.$el.style.opacity = this.opacity;
          this.$el.style.transform = `scale(${this.opacity*.1+.9})`;
        },
      });
    });
  }
};
</script>
<style lang="scss">
div.map {
  position: relative;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;

  font-family: "Josefin Sans";
  font-style: normal;
  font-weight: 400;
  max-width: 1920px;
  margin: 0 auto;

  // background: url("/img/maps/barcelona.jpg") no-repeat center;


  div#map {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;

    a[href^="http://maps.google.com/maps"]{display:none !important}
    a[href^="https://maps.google.com/maps"]{display:none !important}

    // .gm-style-cc, a { display:none; }
    .gmnoprint a, .gmnoprint span, .gm-style-cc {
        display:none;
    }
    .gmnoprint div {
        background:none !important;
    }
  }

  div.info {
    background-color: rgba(255,255,255,.9);
    position: relative;
    width: 400px;
    height: 300px;
    padding: 10px;
    margin: 10px;
  }

  div.images {
    background-color: rgba(255,255,255,.9);
    position: relative;
    width: 600px;
    height: 300px;
    padding: 10px;
    margin: 10px;
  }
}




</style>
