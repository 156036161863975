<template>
  <div id="app">
    <canvas ref="canvas" />
    <Points
      v-if="points"
      :points="points"
      :zoomLevel="this.zoomLevel"
      ref="points"
      @click-place="onClickPoint"
      @click-cluster="onClickCluster"
    />
    <button v-if="this.zoomLevel < 3" @click="zoomLevel++" class="zoom zoom-in">
      +
    </button>
    <button v-if="this.zoomLevel > 0" @click="zoomLevel--" class="zoom zoom-out">
      -
    </button>
    <router-view v-if="isLoaded" />
    <div class="loader" ref="loader" v-if="!isLoaded">
      <div class="percentage" ref="percentage" />
    </div>
  </div>
</template>
<script>
import Scene3d from "@/components/scene3d/Scene3d";
import Points from "@/components/scene3d/Points";
import DataLoader from "@/core/DataLoader";
import gsap from "gsap";

export default {
  data() {
    return {
      isLoaded: false,
      points: null,
      zoomLevel: 0,
    };
  },
  components: {
    Points,
  },
  watch: {
    points() {
      this.$nextTick(() => {
        this.$refs.points.animateIn();
      });
    },
    zoomLevel() {
      this.points = DataLoader.CLUSTERED_PLACES[this.zoomLevel];
      this.scene3d.setZoomLevel(this.zoomLevel);
      this.scene3d.setPlaces(this.points);
    },
  },
  methods: {
    onClickPoint(point) {
      this.scene3d.rotateToPoint(point);
      this.$router.push({path:`/map/${point.slug}`})
      // this.zoomLevel = 3;
    },
    onClickCluster(point) {
      console.log(point);
      // search for point in zoom levels from next one
      for (let i = this.zoomLevel+1; ; i++) {
        let points = DataLoader.CLUSTERED_PLACES[i];
        let exists = false;
        for (let j=0; j<points.length; j++) {
          if (point.slug==points[j].slug){
            exists = true;
            break
          }
        }
        // Set zoom level to first level without the cluster.
        // Last level is always the point.
        if (!exists){
          this.zoomLevel = i;
          break
        }
      }
      this.scene3d.setZoomLevel(this.zoomLevel);
      this.scene3d.rotateToPoint(point);
    },
  },
  mounted() {
    this.scene3d = new Scene3d(this.$refs.canvas);
    window.loaderRect = this.$refs.loader.getBoundingClientRect();
    this.scene3d.onStartLoadingData = () => {
      gsap.to(this.$refs.loader, {
        scaleX: 1,
        ease: "power3.inOut",
        duration: 1,
        onComplete: () => {
          DataLoader.loadPlaces(() => {
            this.scene3d.startLoading();
          });
        },
      });
    };
    this.scene3d.onProgressData = (d1, d2, d3) => {
      gsap.to(this.$refs.percentage, {
        scaleX: d2 / d3,
        ease: "power3.out",
        onComplete:
          d2 === d3
            ? () => {
                this.isLoaded = true;
                this.points = DataLoader.CLUSTERED_PLACES[this.zoomLevel];
                this.scene3d.animateIn();
                this.scene3d.setPlaces(this.points);
              }
            : null,
      });
    };
    gsap.set([this.$refs.loader, this.$refs.percentage], { scaleX: 0 });
  },
  destroyed() {
    this.scene3d.destroy();
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  padding: 0;
  margin: 0;
}
body,
html {
  padding: 0;
  margin: 0;
  overflow: hidden;
  background: #000;
}

div.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 200px;
  height: 8px;
  background: rgba(147, 199, 81, 0.5);
  margin-left: -100px;
  margin-top: -4px;
  will-change: transform;

  div.percentage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(147, 199, 81);
  }
}

canvas {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  background: #000;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

button.zoom {
  width: 50px;
  height: 50px;
  font-size: 40px;
  position: absolute;
  right: 50px;
  border-radius: 50%;
  border-width: 0;
  background: rgba(255, 255, 255, 0.8);
  // background: #73BF43;
}

button.zoom-in {
  bottom: 200px;
}
button.zoom-out {
  bottom: 100px;
}
</style>
