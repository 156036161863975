<template>
  <div class="points">
    <div
      class="point"
      v-for="point in this.points"
      :key="`${point.slug}-${zoomLevel}`"
      ref="points"
      @click="onClickPoint(point)"
      :class="{ cluster: point.isCluster }"
    >
      <div class="point-content"></div>
    </div>
  </div>
</template>
<script>
import gsap from "gsap";
export default {
  props: {
    points: {
      type: Array,
      default: () => [],
    },
    zoomLevel: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    updatePositions() {
      this.$refs.points.forEach((point, index) => {
        const result = window.scene3d.getPositionForPoint(
          this.points[index].slug
        );
        const position = result.position;

        if (this.points[index].totalPoints > 4) {
          // console.log(this.points[index]);
        }

        // console.log(visible);
        gsap.set(point, {
          x: position.x,
          y: position.y,
          scaleX: !result.isVisible ? 0 : result.scale,
          scaleY: !result.isVisible ? 0 : result.scale,
        });
        gsap.to(point, {
          opacity: result.scale < 0.2 ? 0 : 1,
          duration: 0.3,
        });
      });
      window.requestAnimationFrame(this.updatePositions);
    },
    onClickPoint(point) {
      if (point.isCluster) {
        this.$emit("click-cluster", point);
      } else {
        this.$emit("click-place", point);
      }
    },
    animateIn() {
      this.$refs.points.forEach((point, index) => {
        gsap.to(point.children[0], {
          scaleX: 1,
          scaleY: 1,
          ease: "back.out",
          delay: 1 + index * 0.005,
        });
      });
    },
  },
  mounted() {
    this.updatePositions();
  },
};
</script>
<style lang="scss">
div.points {
  position: fixed;
  top: 0;
  left: 0;
  div.point {
    position: absolute;
    width: 60px;
    height: 30px;
    transform: scale(0);
    margin-left: -30px;
    margin-top: -15px;
    div.point-content {
      background: url("/img/sonar.png") center center no-repeat;
      background-size: contain;
      position: absolute;
      width: 60px;
      height: 30px;
      will-change: transform;
      transform: scale(0);
      user-select: none;
      cursor: pointer;
      &:before {
        content: "";
        position: absolute;
        top: 0px;
        width: 60px;
        height: 2px;
        top: 30px;
        left: 3px;
        z-index: -1;
      }
      &:after {
        width: 3px;
        position: absolute;
        top: 3px;
        height: 27px;
        content: "";
        left: 60px;
      }
    }

    &.cluster {
      width: 80px;
      height: 45px;
      margin-left: -40px;
      margin-top: -23px;
      div.point-content {
        width: 80px;
        height: 45px;
        &:after {
          left: 80px;
          height: 42px;
        }
        &:before {
          top: 45px;
          width: 80px;
        }
      }
    }
  }
}
</style>
