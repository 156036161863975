import * as THREE from "three";
import { TextureLoader } from "three";
import axios from "axios";
import clusterify from "@/core/clusterify";


// function getGEZoom(zoom) { // convert GMap zoom level to GE lookAt range
//   var range = 35200000 / (Math.pow(2, zoom));
//   if (range < 300) range = 300;
//   return range;
// }

// not accurate
// function altitudeToZoom(alt) { // convert GE lookAt range to GMap zoom
//   var zoom = Math.round(Math.log(35200000 / alt) / Math.log(2));
//   if (zoom < 0) zoom = 0;
//   else if (zoom > 19) zoom = 19;
//   return zoom;
// }

function altitudeToZoom(altitude, screenHeight = 640, FOV = 60) {
  const baseScale = 156543.03392; // meters per pixel at zoom level 0 at the equator
  const radians = (angle) => (angle * Math.PI) / 180;
  const scale = (altitude * Math.tan(radians(FOV / 2)) * 2) / screenHeight;
  const zoomLevel = Math.log2(baseScale / scale);
  return Math.round(zoomLevel);
}

export default {
  ASSETS: [],
  isLoaded: false,
  hasWebp: false,
  PLACES: [],
  CLUSTERED_PLACES: [],
  COUNTRIES: {},
  COUNTRY: "",
  loadPlaces(cb) {
    const places = axios.get("/data/locations.json").then((result) => {
      // let locations = result.data.places;
      this.PLACES = result.data.places;
      for (let i = 0; i < this.PLACES.length; i++) {
        // parse location from URL
        // 3D: https://www.google.com/maps/@44.6492444,-63.519901,791a,35y,268h,78.57t/data=!3m1!1e3?hl=en
        // 2D: https://www.google.com/maps/@44.6758376,-63.571101,13098m/data=!3m1!1e3!5m1!1e4?hl=en
        let m = this.PLACES[i].url.match(/@([^/]+)/);
        let fields = m[1].split(",");
        this.PLACES[i].location = {
          lat: +fields[0],
          lng: +fields[1]
        };
        for (let j = 2; j < fields.length; j++) {
          switch (fields[j].substr(-1, 1)) {
            case "a": // altitude (in 3D)
              this.PLACES[i].location.altitude = +fields[j].substr(0, fields[j].length - 1);
              this.PLACES[i].location.zoom = altitudeToZoom(this.PLACES[i].location.altitude);
              break;
            case "m": // meters (= altitude in 2D)
              this.PLACES[i].location.altitude = +fields[j].substr(0, fields[j].length - 1);
              this.PLACES[i].location.zoom = altitudeToZoom(this.PLACES[i].location.altitude);
              break;
            case "h": // heading
              this.PLACES[i].location.heading = +fields[j].substr(0, fields[j].length - 1);
              break;
            case "t": // tilt
              this.PLACES[i].location.tilt = +fields[j].substr(0, fields[j].length - 1);
              break;
          }
        }
        this.PLACES[i].slug = this.PLACES[i].name.toLowerCase().replace(/\s/g, "-");
        console.log(i, this.PLACES[i]);
      }
      this.CLUSTERED_PLACES = clusterify(this.PLACES, 4, 2);
    });

    const codes = axios.get("/data/countrycodes.json").then((result) => {
      this.COUNTRIES = result.data;
    });

    // const code = axios.get("/data/country.json").then((result) => {
    const code = axios.get(process.env.VUE_APP_GEO_SONAR_URL).then((result) => {
      this.COUNTRY = result.data.countryCode;
    }).catch(() => { }); // ignore errors

    Promise.all([places, codes, code]).then(() => {
      cb();
    });
  },
  loadTextures(cb, cbProgress) {
    let count = 0;
    this.loadingManager = new THREE.LoadingManager(
      () => { },
      (url, loaded, total) => {
        cbProgress(url, loaded, total);
      }
    );
    const textureLoader = new TextureLoader(this.loadingManager);

    this.hasWebp = this.supportWebp();

    [
      "8k_earth_daymap",
      "2k_earth_normal_map",
      "earth-water_png",
      "starmap_4k",
      "2k_earth_clouds",
    ].forEach((textureId) => {
      textureLoader.load(
        `img/textures/earth-nasa-maps/${this.hasWebp ? "webp/" : ""
        }${textureId}.${this.hasWebp ? "webp" : textureId.indexOf("png") > -1 ? "png" : "jpg"
        }`,
        (texture) => {
          count++;
          this.ASSETS[textureId] = texture;
          if (count === 5) {
            cb();
          }
        }
      );
    });
  },
  supportWebp() {
    return false;
    // from https://stackoverflow.com/questions/5573096/detecting-webp-support
    // const elem = document.createElement("canvas");

    // if (elem.getContext && elem.getContext("2d")) {
    //   // was able or not to get WebP representation
    //   return elem.toDataURL("image/webp").indexOf("data:image/webp") == 0;
    // } else {
    //   // very old browser like IE 8, canvas not supported
    //   return false;
    // }
  },
};
