import * as THREE from "three";
import DataLoader from "../../core/DataLoader";
import {
  EffectComposer,
  SMAAImageLoader,
  SMAAEffect,
  SMAAPreset,
  EffectPass,
  RenderPass,
  SavePass,
  VignetteEffect,
  EdgeDetectionMode,
  DepthOfFieldEffect,
} from "postprocessing";
import Stats from "stats.js";
import ScreenQuad from "./background/ScreenQuad";
import gsap from "gsap";
import * as dat from "dat.gui";
import {
  convertLatLonToVec3,
  toScreenPosition,
  trimSlug,
  normalizeWheel,
} from "@/core/utils";
import { createGlowMesh } from "./createGlowMesh";
import Orbit from "./Orbit";

const SIZE = 0.45;
const DIVISIONS = 45;
const MIN_CAMERA_Z = 1.5;
const MAX_CAMERA_Z = 0.3;

export default class Scene3d {
  constructor(canvas) {
    this.canvas = canvas;
    this.WIDTH = window.innerWidth;
    this.HEIGHT = window.innerHeight;

    this.createScene();
    window.addEventListener("resize", this.onResizeWindow);
    this.onResizeWindow();

    this.onStartLoadingData = () => {};
    this.onProgressData = () => {};
    this.onCompleteData = () => {};

    this.stats = new Stats();
    this.stats.showPanel(0); // 0: fps, 1: ms, 2: mb, 3+: custom
    this.stats.dom.style.bottom = "0px";
    this.stats.dom.style.top = "50%";
    document.body.appendChild(this.stats.dom);

    this.raycaster = new THREE.Raycaster();
    this.createPostProcessing();

    window.scene3d = this;
  }

  getPositionForPoint(slug) {
    const point = this.points[trimSlug(slug, 5)];
    const position = toScreenPosition(point, this.camera, this.renderer);
    this.raycaster.setFromCamera(
      { x: position.ox, y: position.oy },
      this.camera
    );
    let visible = false;
    let scale = 1;
    let distance = 0;
    if (this.globeHorizontal) {
      const result = this.raycaster.intersectObjects(
        [this.globe, point],
        false
      );
      for (let i = 0; i < result.length; i++) {
        if (result[i].object === this.globe) {
          visible = false;
          scale = 0;
          break;
        } else if (result[i].object === point) {
          visible = true;
          scale = Math.min(
            1,
            (Math.abs(result[i].distance - this.camera.position.z) / SIZE) * 2
          );
          distance = result[i].distance;
          scale = Math.max(0, scale);
          break;
        }
      }
    }

    return {
      position,
      isVisible: visible,
      scale,
      distance: distance * 10,
    };
  }

  startLoading() {
    DataLoader.loadTextures(this.onLoadTextures, this.onProgressData);
  }

  setPlaces(points) {
    if (this.points) {
      this.points.forEach((pt) => {
        this.pointsElement.remove(pt);
      });
      this.globe.remove(this.pointsElement);
      this.points = null;
      this.pointsElement = null;
    }
    this.points = [];

    const material = new THREE.MeshBasicMaterial({
      color: 0xff0000,
      map: null,
      transparent: true,
    });
    const geo = new THREE.PlaneBufferGeometry(0.1, 0.05, 1, 1);
    this.pointsElement = new THREE.Group();
    points.forEach((point) => {
      if (point.totalPoints > 4) {
        console.log(point);
      }
      const position = convertLatLonToVec3(
        point.location.lat,
        point.location.lng
      ).multiplyScalar(SIZE * 1.01);

      const mesh = new THREE.Mesh(geo, material);
      mesh.visible = false;
      this.pointsElement.add(mesh);
      this.points[trimSlug(point.slug, 5)] = mesh;
      mesh.position.set(position.x, position.y, position.z);
    });

    this.lookObject = new THREE.Mesh(geo, material);
    this.lookObject.visible = false;
    this.lookObject.scale.multiplyScalar(12);
    this.globe.add(this.lookObject);

    this.globe.add(this.pointsElement);
  }

  setZoomLevel(level) {
    this.zoomLevel = MIN_CAMERA_Z + (level * (MAX_CAMERA_Z - MIN_CAMERA_Z)) / 4;
    this.controls.setZoomLevel(level);
  }

  onLoadTextures = () => {
    this.onCompleteData();
    this.createStars();
    // this.createGlobe();
  };

  createStars() {
    this.stars = new THREE.Mesh(
      new THREE.SphereBufferGeometry(20, 64, 64),
      new THREE.MeshBasicMaterial({
        map: DataLoader.ASSETS["starmap_4k"],
        // alphaMap: DataLoader.ASSETS["starmap_4k"],
        transparent: true,
        side: THREE.BackSide,
      })
    );

    this.starsVertical = new THREE.Group();
    // this.starsVertical.add(this.stars);
    this.scene.add(this.starsVertical);
  }

  createScene() {
    this.renderer = new THREE.WebGLRenderer({
      antialias: false,
      canvas: this.canvas,
      alpha: false,
      depth: true,
      powerPreference: "high-performance",
    });
    this.renderer.setClearColor(0x000000);

    this.scene = new THREE.Scene();
    this.camera = new THREE.PerspectiveCamera(
      45,
      this.WIDTH / this.HEIGHT,
      0.01,
      100
    );
    this.zoomLevel = MIN_CAMERA_Z;
    this.camera.position.set(0, 0, 1.5);

    window.camera = this.camera;

    // this.renderer.physicallyCorrectLights = true;
    // this.renderer.outputEncoding = THREE.sRGBEncoding;
    // this.renderer.shadowMap.enabled = true;
    // this.renderer.toneMapping = THREE.ACESFilmicToneMapping;
  }

  createPostProcessing() {
    this.composer = new EffectComposer(this.renderer);

    const smaaImageLoader = new SMAAImageLoader();

    this.assets = [];
    smaaImageLoader.load(([search, area]) => {
      this.smaaEffect = new SMAAEffect(
        search,
        area,
        SMAAPreset.HIGH,
        EdgeDetectionMode.LUMA
      );

      this.start3D();
    });

    this.clock = new THREE.Clock();
    this.background = new ScreenQuad();
    this.background.name = "background";
    this.clock = new THREE.Clock();
    // this.scene.add(this.background);
    // this.background.material.transparent = true;

    this.render();
    this.onResizeWindow();
  }

  start3D() {
    this.vignetteEffect = new VignetteEffect({
      darkness: 0.35,
      eskil: false,
    });
    // this.vignetteEffect.darkness = 0.1;

    this.savePass = new SavePass();

    this.smaaPass = new EffectPass(
      this.camera,
      this.smaaEffect,
      this.vignetteEffect
    );

    this.depthOfFieldEffect = new DepthOfFieldEffect(this.camera, {
      focusDistance: 0.0,
      focalLength: 0.048,
      bokehScale: 5.0,
      height: 1024,
    });

    this.dofPass = new EffectPass(this.camera, this.depthOfFieldEffect);

    this.renderPass = new RenderPass(this.scene, this.camera);
    this.composer.addPass(this.renderPass);
    this.composer.addPass(this.dofPass);
    this.composer.addPass(this.smaaPass);

    window.dof = this.depthOfFieldEffect;

    gsap.fromTo(
      this.canvas,
      {
        opacity: 0,
      },
      { opacity: 1, duration: 1 }
    );

    this.onStartLoadingData();

    window.composer = this.composer;
  }

  animateIn() {
    this.createGlobe();
    gsap.from(this.camera.position, {
      z: this.camera.position.z * 1.1,
      ease: "power4.inOut",
      duration: 3,
    });
    gsap.fromTo(
      [this.globeMaterial],
      {
        opacity: 0,
      },
      {
        opacity: 1,
        ease: "power4.inOut",
        duration: 3,
      }
    );
    gsap.fromTo(
      this.glow.material.uniforms.opacity,
      {
        value: 0,
      },
      {
        value: 1,
        ease: "power4.inOut",
        duration: 3,
      }
    );
    gsap.fromTo(
      [this.cloudsMaterial],
      {
        opacity: 0,
      },
      {
        opacity: 0.3,
        ease: "power4.inOut",
        duration: 3,
      }
    );
    gsap.fromTo(
      this.stars.material,
      {
        opacity: 0,
      },
      {
        opacity: 1,
        delay: 1,
        ease: "linear.none",
        duration: 2,
      }
    );
    gsap.fromTo(
      this.depthOfFieldEffect.circleOfConfusionMaterial.uniforms.focusDistance,
      {
        value: 0.203,
      },
      {
        value: 0,
        ease: "power4.inOut",
        duration: 2,
      }
    );
  }

  rotateToCountry(code, animate) {
    let latlong = DataLoader.COUNTRIES[code];
    console.log(code, latlong)
    if (!latlong) {
      console.log("Country "+code+" not found, defaulting to US");
      latlong = [38,-97];
    }
    const y = latlong[0]*Math.PI/180;
    const x = (270-latlong[1])*Math.PI/180 + .3 + .5 * (1-Math.cos(y));
    if (animate) {
      this.controls.updateRotation(x,y);
    } else {
      this.globeHorizontal.rotation.x = y; // Note: swapped
      this.globeHorizontal.rotation.y = x;
    }
  }
  createGlobe() {
    this.globeGeometry = new THREE.SphereBufferGeometry(
      SIZE,
      DIVISIONS,
      DIVISIONS
    );
    this.globeMaterial = new THREE.MeshPhongMaterial({
      map: DataLoader.ASSETS["8k_earth_daymap"],
      normalMap: DataLoader.ASSETS["2k_earth_normal_map"],
      specularMap: DataLoader.ASSETS["earth-water_png"],
      specular: new THREE.Color("#333333"),
      shininess: 0,
    });
    const globe = new THREE.Mesh(this.globeGeometry, this.globeMaterial);
    this.globe = globe;

    this.glow = createGlowMesh(this.globeGeometry, {
      backside: true,
      color: "lightskyblue",
      size: SIZE * 0.2,
      power: 3.5, // dispersion
      coefficient: 0.1,
    });

    this.scene.add(this.glow);

    this.globeHorizontal = new THREE.Group();
    this.rotateToCountry(DataLoader.COUNTRY);
    this.globeHorizontal.add(globe);

    this.globeVertical = new THREE.Group();
    this.globeVertical.add(this.globeHorizontal);
    this.scene.add(this.globeVertical);

    this.globeVertical.position.x = -0.35;
    this.globeVertical.rotation.y = 0.25;
    this.glow.position.x = -0.35;

    this.scene.add(new THREE.AmbientLight(0x333333));

    const directionalLight = new THREE.DirectionalLight(0xffffff, 1.0);
    directionalLight.position.set(5, 3, 5);
    this.scene.add(directionalLight);

    this.cloudsMaterial = new THREE.MeshBasicMaterial({
      transparent: true,
      color: 0xffffff,
      depthWrite: false,
      depthTest: false,
      alphaMap: DataLoader.ASSETS["2k_earth_clouds"],
    });
    this.clouds = new THREE.Mesh(
      new THREE.SphereBufferGeometry(SIZE * 1.0, DIVISIONS, DIVISIONS),
      this.cloudsMaterial
    );
    this.globeHorizontal.add(this.clouds);

    // this.stars = new THREE.Mesh(
    //   new THREE.SphereBufferGeometry(20, 64, 64),
    //   new THREE.MeshBasicMaterial({
    //     map: DataLoader.ASSETS["starmap_4k"],
    //     // alphaMap: DataLoader.ASSETS["starmap_4k"],
    //     transparent: true,
    //     side: THREE.BackSide,
    //   })
    // );
    this.globeHorizontal.add(this.stars);

    this.canvas.addEventListener("mousedown", this.onMouseDown);
    window.addEventListener("mousemove", this.onMouseMove);

    /* Add the event listeners for each event. */
    // window.addEventListener("wheel", this.onMouseWheel);
    // window.addEventListener("mousewheel", this.onMouseWheel);
    // window.addEventListener("DOMMouseScroll", this.onMouseWheel);
    // this.createGui();

    this.controls = new Orbit(this.globeHorizontal, 1);
  }

  rotateToPoint(point) {
    var y = point.location.lat * Math.PI/180;
    var x = (270-point.location.lng) * Math.PI/180 + .3 + .5 * (1-Math.cos(y));
    console.log("rotateToPoint", x, y);
    this.controls.updateRotation(x, y);
  }

  destroy() {
    window.cancelAnimationFrame(this.raf);
    if (this.gui) {
      this.gui.destroy();
    }
  }

  onMouseWheel = (evt) => {
    const event = normalizeWheel(evt);
    this.zoomLevel += event.pixelY / 1000;
    this.zoomLevel = Math.min(
      MIN_CAMERA_Z,
      Math.max(MAX_CAMERA_Z, this.zoomLevel)
    );
    this.zoomPercentage =
      (this.zoomLevel - MAX_CAMERA_Z) / (MIN_CAMERA_Z - MAX_CAMERA_Z);
  };

  createGui() {
    this.gui = new dat.GUI();
    this.country = { code: DataLoader.COUNTRY };
    const unordered = Object.keys(DataLoader.COUNTRIES).reduce(
      (obj, id) => {
        obj[DataLoader.COUNTRIES[id][2]+", ["+id+"]"] = id;
        return obj;
      },
      {}
    );
    const sorted = Object.keys(unordered).sort().reduce(
      (obj, key) => {
        obj[key] = unordered[key];
        return obj;
      },
      {}
    );

    this.gui
      .add(this.country, "code", sorted)
      .name("country code")
      .onChange(code => this.rotateToCountry(code,true));
    this.gui.add(this.clouds, "visible").name("show clouds");
    this.gui.add(this.background, "visible").name("show waves");
    this.gui.add(this.stars, "visible").name("show stars");

    const atmosphereFolder = this.gui.addFolder("Atmosphere");
    atmosphereFolder.add(this.glow, "visible").name("enabled");
    atmosphereFolder
      .add(this.glow.material.uniforms.coefficient, "value", 0, 1, 0.025)
      .name("coefficient");
    atmosphereFolder
      .add(this.glow.material.uniforms.power, "value", 0, 10, 0.5)
      .name("power");
    atmosphereFolder.open();

    // this.gui.show();
    this.gui.close();
  }

  onMouseDown = (evt) => {
    if (!this.hitGlobe) {
      return;
    }
    this.iniPos = {
      x: this.starsVertical.rotation.x,
      y: this.stars.rotation.y,
    };
    this.iniMouse = { x: evt.clientX, y: evt.clientY };
    this.iniCircleMouse = {
      x: (evt.clientX - this.HALF_WIDTH) / this.HALF_WIDTH,
      y: (evt.clientY - this.HALF_HEIGHT) / this.HALF_HEIGHT,
    };
    this.lastCircleMouse = this.iniCircleMouse;
    this.isMouseDown = true;

    window.addEventListener("mouseup", this.onMouseUp);
    window.addEventListener("mouseleave", this.onMouseUp);

    document.body.style.cursor = "grabbing";
  };

  onMouseMove = (evt) => {
    if (!this.isMouseDown) {
      this.raycaster.setFromCamera(
        {
          x: (evt.clientX - this.HALF_WIDTH) / this.HALF_WIDTH,
          y: (evt.clientY - this.HALF_HEIGHT) / this.HALF_HEIGHT,
        },
        this.camera
      );
      const result = this.raycaster.intersectObjects([this.globe], false);
      if (result.length > 0) {
        this.hitGlobe = true;
        document.body.style.cursor = "grab";
        this.isMouseOver = true;
      } else {
        this.hitGlobe = false;
        document.body.style.cursor = "auto";
        this.isMouseOver = false;
      }
      return;
    }

    const currentCircleMouse = {
      x: (evt.clientX - this.HALF_WIDTH) / this.HALF_WIDTH,
      y: (evt.clientY - this.HALF_HEIGHT) / this.HALF_HEIGHT,
    };

    this.controls.move(
      currentCircleMouse.x - this.lastCircleMouse.x,
      currentCircleMouse.y - this.lastCircleMouse.y
    );
    this.lastCircleMouse = currentCircleMouse;
  };

  onMouseUp = () => {
    this.isMouseDown = false;
    document.body.style.cursor = "auto";
    window.removeEventListener("mouseup", this.onMouseUp);
    window.removeEventListener("mouseleave", this.onMouseUp);
  };

  onResizeWindow = () => {
    this.WIDTH = window.innerWidth;
    this.HEIGHT = window.innerHeight;
    this.HALF_WIDTH = this.WIDTH / 2;
    this.HALF_HEIGHT = this.HEIGHT / 2;

    this.camera.aspect = this.WIDTH / this.HEIGHT;
    this.camera.updateProjectionMatrix();
    // this.renderer.setSize(this.WIDTH, this.HEIGHT, true);

    if (this.composer) {
      this.composer.setSize(this.WIDTH, this.HEIGHT);
    }
  };

  render = () => {
    this.stats.begin();
    if (this.points) {
      for (var slug in this.points) {
        this.points[slug].lookAt(this.camera.position);
      }
    }

    this.camera.position.z += (this.zoomLevel - this.camera.position.z) * 0.1;

    if (this.glow) {
      this.glow.position.x =
        -0.35 -
        ((this.camera.position.z - MIN_CAMERA_Z) /
          (MIN_CAMERA_Z - MAX_CAMERA_Z)) *
          0.15;

      this.globeVertical.position.x = this.glow.position.x;
    }

    this.renderer.render(this.scene, this.camera);

    if (this.globe) {
      this.globe.parent.children[1].rotation.y -= 0.0001;
    }

    if (this.background && this.background.material.uniforms) {
      this.background.material.uniforms.uTime.value = this.clock.getElapsedTime();
    }
    this.stats.end();
    this.raf = window.requestAnimationFrame(this.render);
  };
}
