import * as THREE from "three";
import gsap from "gsap";

export default class Orbit {
  constructor(object, zoomLevel = 0) {
    this.object = object;
    this.zoomLevel = zoomLevel;

    // How far you can orbit vertically, upper and lower limits.
		// Range is 0 to Math.PI radians.
		this.minPolarAngle = -Math.PI/2*0.9; // radians
		this.maxPolarAngle = Math.PI/2*0.9; // radians

    // Internals
    this.spherical = new THREE.Spherical(1, this.object.rotation.y, this.object.rotation.x);
  }

  setZoomLevel(zl) {
    this.zoomLevel = zl;
  }

  updateFinalRotation(rotation) {
    this.auxObject.rotation.x = rotation.x;
    this.auxObject.rotation.y = rotation.y;
    this.auxObject.rotation.z = rotation.z;
  }

  move(diffX, diffY) {
    this.spherical.phi += diffX * 3 / (this.zoomLevel + 1);
    this.spherical.theta += diffY * 3 / (this.zoomLevel + 1);

    this.updateRotation(this.spherical.phi, this.spherical.theta);
  }

  updateRotation(x,y) {
    this.spherical.phi = x;
    this.spherical.theta = y;

    // limit vertical rotation
    this.spherical.theta = Math.max(this.minPolarAngle, Math.min(this.maxPolarAngle, this.spherical.theta));

    while (this.object.rotation.y < x-Math.PI) {
      this.object.rotation.y += 2*Math.PI;
    }
    while (this.object.rotation.y > x+Math.PI) {
      this.object.rotation.y -= 2*Math.PI;
    }

    this.num = 0;

    var from = new THREE.Euler().copy(this.object.rotation);
    var to = new THREE.Euler(y, x); // note reverse order

    gsap.killTweensOf(this);
    gsap.to(this, {
      num: 1,
      ease: "circ.out",
      duration: 1,
      onUpdate: () => {
        this.object.rotation.x = from.x + (to.x-from.x)*this.num;
        this.object.rotation.y = from.y + (to.y-from.y)*this.num;
      },
    });
  }

}
